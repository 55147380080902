import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Buyer_Challenges_-_GBR_Network_-_30-05-2024_N-removebg-preview.png";
// import production from "../../../images/challangesImg/production (2).svg";
import Ecosystem from "../../images/Traceable Food.svg";
import Brand from "../../images/marketing (2).svg";
import Marketing from "../../images/Setup of dark store network.svg";
import Finance from "../../images/Rural network.svg";

export default function BuyerChallenges() {
  const challanges =[{
    title:"Traceable Food",
    description:"Tracing food sources poses a challenge for F&B buyers due to complex supply chains, making it difficult to ensure transparency and accountability for quality and safety standards.",
    image:Ecosystem
  },
  {
    title:"Setup of dark store network ",
    description:"Establishing a dark store network presents challenges for F&B buyers due to the heavy investment required and potential disruptions to traditional supply chains, leading to uncertainty in sourcing and fulfillment.",
    image:Marketing
  },


]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        F&B Buyers Challenges
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={e.image} className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p>
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}
         
          {/* <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={Finance} className="mb-3" />
                <h6 className="mb-2">Extra Source of Income</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
           

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-4 mb-3 ">
                <img src={Ecosystem} className="mb-3" />
                <h6 className="mb-2">Ecosystem</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Marketing} className="mb-3" />
                <h6 className="mb-2">Marketing</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been
                </p>
              </div>
            </Col>

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Brand} className="mb-3" />
                <h6 className="mb-2">Brand</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum
                </p>
              </div>
            </Col> */}
          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
