import React from "react";
import { Container } from "@material-ui/core";
import StepCards from "../stepCards/StepCards";
import step1 from "../../images/pstep1.png";
import step2 from "../../images/login.png";
import step3 from "../../images/pstep3.png";

export default function AgriFoodManufacturersJourney() {
  const stepCardContent = [
    {
      title: "Step 1",
      bodycopy: "Partner as business entity opens an account.",
      img: step1,
    },
    {
      title: "Step 2",
      bodycopy:
        "Login and manage their respective outlets (Farms and Processing Units) and their staff.",
      img: step2,
    },
    {
      title: "Step 3",
      bodycopy: "Created staff-members login and perform the assigned jobs.",
      img: step3,
    },
  ];
  return (
    <div className="subSectionWrapper partner-journey-container">
      <h4 className="container-title text-center">Food Processing Enterprises Journey</h4>
      <div className="section3BodyContainer justify-content-md-center">
        {stepCardContent.map((e) => {
          return (
            <StepCards
              src={e.img}
              stepTitle={e.title}
              stepContent={e.bodycopy}
            />
          );
        })}
      </div>
    </div>
  );
}
