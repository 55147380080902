import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import FFSSection from './FFSSection'
import './FFS.css'

export default function FFS() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner     className="FFS text-white"
         bannerText="Farmer Facilitation Service"/>
         <FFSSection/>
    <FooterComponent/>
    </>
  )
}
