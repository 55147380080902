import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
import "./ContractFarming.css"
import SocialMarketNavbar from '../SocialMarketNavbar/SocialMarketNavbar';

export default function ContractFarming() {
  const userTypes= ["AssetDeveloper"]

  
 
  return (
    <>
    <HeaderComponent className="noShadow"/>
    <SocialMarketNavbar/>
    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText="Buyers looking for Contract Farming"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 

<div className='subSectionWrapper'>
    <ClubMainPage type={userTypes}/>
    </div>
           <FooterComponent/>
    </>
  )
}
