import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import "./RetailInvestors.css";
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
export default function RetailInvestors() {
    const navData = [{to:"/RetailInvestors", link:"Retail Investors"},
    {to:"/InstitutionalInvestors", link:"Institutional Investors"}
  ]  
  return (
    <>
    <HeaderComponent className="noShadow"/>
    <SecondaryNavbar props={navData}/>
    <UpdatesBanner 
         className="retailInvestorBanner text-white"
         bannerText="Retail Investor"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        />
            <FooterComponent/>
    </>
  )
}
