import React from 'react'
import HeaderComponent from '../../WebSite/Header/HeaderComponent'
import FooterComponent from '../../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../../UpdatesBanner/UpdatesBanner'
import HomeSubSection from '../../WebSite/HomeSubSection/HomeSubSection'
import './FinancialService.css'

export default function FinancialService() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner bannerText="Financial Service" className="financial-service-bg"/>

    <div className="main-wrapper2">
        <div className="subSectionWrapper">
    <HomeSubSection
        className3="loan-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Loan"
        description="GBR facilitates loan services to agri-producers, empowering them with financial support to enhance their farming operations. Through tailored loan products and accessible lending processes, GBR assists agri-producers in securing capital for purchasing seeds, fertilizers, equipment, and other inputs necessary for their agricultural activities. By providing financial assistance, GBR contributes to the growth and sustainability of agricultural ventures, ultimately fostering the development of rural communities and promoting food security.
        "
      
       
      />
      </div>
      </div>
    <FooterComponent/>
    </>
  )
}
