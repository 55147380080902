import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/CBO-Community_Based_Network_Challenges_02-02-2024-removebg-preview.png";
import Ecosystem from "../../images/loan.svg";
import Finance from "../../images/challangesImg/loan.svg";


export default function CBOChallenges() {
  const challanges =[{
    title:"Technology",
    description:"Technology is indispensable for CBOs, serving as a necessity to create, manage, and operate networks, thereby optimizing their operational efficiency and community impact.",
    image:Ecosystem
  },
  {
    title:"Loan",
    description:"Access to loans is a vital requirement for Community-Based Organizations (CBOs) engaged in trading, providing essential capital to sustain and expand their business activities within the community.    ",
    image:Finance
  },

]
  return (
    <div className="main-wrapper2">
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        Community Based Organisation Needs
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} alt ="Icon" className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e)=>{
              return <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={e.image} className="mb-3" />
                <h6 className="mb-2">{e.title}</h6>
                <p>
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}
         
          
          </Row>
   
        </Col>
      </Row>
    </Container>
    </div>
  );
}
