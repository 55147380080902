import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/logistic-provider-challenges/Logistic_Providers_Challenges_-_GBR_Network_-_29-05-2024_-_N-removebg-preview.png";

import CostManagement  from "../../images/logistic-provider-challenges/Cost Management.svg";
import LabourShortage from "../../images/logistic-provider-challenges/labor shortages.svg";
import RealTimeTracking from "../../images/logistic-provider-challenges/Real-Time Tracking.svg";
import  SupplyChainDisruptions from "../../images/logistic-provider-challenges/supply chain disruptions.svg";

const LogisticProvidersChallenges = () => {
    const challanges =[
    
 
     
      {
        title:"Efficiency and Cost ",
        description:" Optimizing last-mile delivery, which is often the most expensive and least efficient part of the supply chain. ",
        image:CostManagement
      },
      {
        title:"Real-Time Tracking",
        description:"A key difficulty faced by logistics providers is ensuring the provision of accurate real-time tracking information to customers and stakeholders.",
        image:RealTimeTracking
      },
      {
        title:"Supply chain disruptions",
        description:"There is increasing pressure on agrifood brands to adopt sustainable practices throughout their supply chains, from sourcing raw materials to packaging and distribution. This includes reducing greenhouse gas emissions, minimizing waste, and promoting ethical labor practices.",
        image:SupplyChainDisruptions
      },
    
      {
        title:"Labor/Driver shortages",
        description:"Addressing the shortage of qualified drivers, particularly for long-haul trucking.",
        image:LabourShortage
      },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
    //  {
    //     title:"Easy Technology adoption ",
    //     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by agri-producers.",
    //     image:Technology
    //   }
    ]
    return(
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
        Logistic Providers challenges
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex">
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange Icon" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
    )
};

export default LogisticProvidersChallenges
