import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Agri-Business-removebg-preview.png";
import production from "../../../images/LandingPageChallenge/production (2).svg";
import Ecosystem from "../../../images/LandingPageChallenge/blockchain.svg";
import Export from "../../../images/LandingPageChallenge/export.svg";
import Sourcing from "../../../images/LandingPageChallenge/global-search.svg";
import Finance from "../../../images/LandingPageChallenge/income.svg";
import Technology from "../../../images/LandingPageChallenge/nanotechnology.png" ;

// import "./ChallangesSection.css";

export default function LandingPageChallenge() {
  return (
    <Container className="challenge-container subSectionWrapper">
            <h2 className="container-title mb-md-5 text-center"> Agribusiness challenges</h2>

      <Row className="align-items-center" >

        <Col md={7} lg={4} className="mx-md-auto">
          <img src={challangeImg} className="mb-md-0 mb-4" />
        </Col>

        <Col md={12} lg={8}>
          <Row className="challangesRow">
            <Col md={6} lg={4} sm={6} xs={10}  className="d-flex">
              <div className="challangeCards mb-md-4 mb-3">
                <img src={Sourcing} className="mb-3" />
                <h6 className="mb-2">Sourcing</h6>
                <p>
                One of the challenges faced in sourcing is the difficulty of obtaining good-quality raw materials at a price that is affordable.                </p>
              </div>
            </Col>

            <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-4 mb-3">
                <img src={production} className="mb-3" />
                <h6 className="mb-2">Production</h6>
                <p>
                Agribusiness encounters production challenges due to the scarcity of quality raw materials, hindering efforts to enhance yields.                 </p>
              </div>
            </Col>

            <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-4 mb-3">
                <img src={Export} className="mb-3" />
                <h6 className="mb-2">Export</h6>
                <p>
                  Limited access to export network & connection, Lack of understand of international market including consumer preference, market trends 
                  & distribution channels. 
                </p>
              </div>
            </Col>

            <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Ecosystem} className="mb-3" />
                <h6 className="mb-2">Eco-Advisory</h6>
                <p>
                There is lack of ecosystem for agribusiness from where they get support in terms of advisory, training , certification, documentation , government schemes etc.
                </p>
              </div>
            </Col>
            <Col md={6} lg={4}  sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Technology} className="mb-3" />
                <h6 className="mb-2">Eco-Technology</h6>
                <p>
                Lack of technology ecosystem, adoption and access to right industry 4.0 enabled machinery to improve productivity, efficiency, and ability to compete in the market .             </p>
              </div>
            </Col>
            <Col md={6} lg={4}  sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Finance} className="mb-3" />
                <h6 className="mb-2">Finance</h6>
                <p>
                Agribusiness lacks diverse financing options, often resorting to banks and government grants. There's a demand for affordable and easy alternatives to sustain successful operations.                </p>
              </div>
            </Col>

         
        
            
          </Row>
  
        </Col>
      </Row>
    </Container>
  );
}
