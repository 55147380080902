import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Agri-Business_Challenges_13-02-2024-removebg-preview.png";
import Ecosystem from "../../../images/AgriBusinessChallenges/trust.svg";
import Brand from "../../../images/challangesImg/brand.svg";
import Technology from "../../../images/global 2.svg";

import Marketing from "../../../images/challangesImg/megaphone.svg";
import Finance from "../../../images/AgriBusinessChallenges/human-resources.svg";
import loan from "../../../images/management (1).svg";

// import "./ChallangesSection.css";

export default function AgriBusinessChallanges() {
  const challanges = [
    //   {
    //   title:"Agri-Trust Gap",
    //   description:"Overcoming trust issues in agri-business as assets are provided to farmers, posing a key challenge in fostering reliable partnerships.",
    //   image:Finance
    // },

    {
      title: "Robust Production Process",
      description:
        "There is a need of  a robust production framework that enhances trust and collaboration specifically tailored for track and trace of agricultural assets distributed across the farms  or rural agri-entrepreneur network. The framework should address concerns related to transparency, accountability, and asset life-cycle management.",
      image: Ecosystem,
    },
    {
      title: "Setting up rural agri-entrepreneur network",
      description:
        "Establishing a cohesive network of rural Agri-entrepreneurs poses a significant hurdle for Agri-businesses, requiring solutions to bridge geographical gaps, empower local communities, and foster collaborative agricultural practices.",
      image: Finance,
    },
    // {
    //   title:"Marketing ",
    //   description:"Farmers lack effective marketing tools, highlighting a crucial need for promoting and selling their products. ",
    //   image:Marketing
    // },
    {
      title: "Market Access",
      description:
        " Market access is essential for farm enterprises to tap into both local and global markets, ensuring sustainable profitability and expansion opportunities.",
        image: Technology,

    },

    // {
    //   title:"Brand ",
    //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
    //   image:Brand
    // }
    {
      title: "Ecosystem ",
      description:
        "An ecosystem is essential for farm enterprises as it provides crucial advisory services for documentation, farming practices, and machinery utilization, facilitating efficient operations and informed decision-making.",
      image: loan,

    },
  ];
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        Farm Enterprise Challenges
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img
            src={challangeImg}
            alt="challange Image"
            className="mb-md-0 mb-4"
          />
        </Col>

        <Col md={8}>
          <Row className="challangesRow justify-content-between ">
            {challanges.map((e) => {
              return (
                <Col md={6} sm={6} xs={10} className="d-flex">
                  <div className="challangeCards  mb-md-4 ">
                    <img src={e.image} alt="Challange Icon" className="mb-3" />
                    <h6 className="mb-2">{e.title}</h6>
                    <p className="mb-0">{e.description}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
