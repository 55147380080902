import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import TitleDescription from "../TitleDescription/TitleDescription";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import "./CBO.css";
import CBOChallenges from "./CBOChallenges";
import CBOService from "./CBOService";
import CBOBenefits from "./CBOBenefits";
import CBOCustomerBenefits from "./CBOCustomerBenefits";
import AgroDealerPricing from "../pricingTabs/AgroDealerPricing";
import CTA from "../CTA/CTA";
import CBOTypes from "../CBOTypes/CBOTypes";

export default function CBO() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        bannerText="Community Based Network"
        className="CBO-banner-bg"
      />
      {/* <Container>
        <TitleDescription
          classname=""
          title="Who are Community Based Organisations"
          description={[
            "Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues in farming, thereby promoting resilient and inclusive agricultural systems at the local level.",
          ]}
        />
      </Container> */}
      {/* <CBOTypes/> */}
      <CBOChallenges />
      <SolutionEcosystem
        type="CBO"
        bodycopy="System and Process for CBO to create, manage and own CBN ( Community Based Network ) for efficient local trade"
      />
      <CBOService/>
      <CBOBenefits />
      <CBOCustomerBenefits />

<Container>
<h2 className="container-title text-center ">CBO Pricing</h2>

<AgroDealerPricing/>
<div className=" subSectionWrapper">
            <CTA
              href="/auth/3/signup"
              heading="Grow with us! Join now for exclusive agro-deals and boost your business. Seize the opportunity today!"
              btnText="Register Now"
              onClick={()=>{
                localStorage.setItem("userType", "CBO")
              }}
            />
          </div>
          </Container>
      <FooterComponent />
    </>
  );
}
