import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function BrandPricingData() {
  const BasicFeature = ["Processed product inventory","GBR Trace","Billing","Access up to 1 Store"];
  const StandardFeature = ["Processed product inventory","GBR Trace","Billing","Access up to 5 Stores, 5 Store operators & 1 Store manager","onMarketplace","Support for Semi-processed products"];
  const PrefferedFeature = ["Processed product inventory","GBR Trace","Billing","Access up to 8 Stores, 8 Store operators & 2 Store manager",  "onMarketplace", "Report", "Support for Semi-processed products & Raw products","Manage contract farming"];
  const PremiumFeature = ["Processed product inventory","GBR Trace","Billing","Access up to 10 Stores, 10 Store operators & 4 Store manager",  "onMarketplace", "Report", "Support for Semi-processed products & Raw products","Manage contract farming" ,"Request for contract-manufacturing", "Social onMarketplace"];
  const CustomizedFeature = ["Processed product inventory","GBR Trace","Billing","Customize number of Stores, Store operator, Store manager",  "onMarketplace", "Report","Support for Semi-processed products & Raw products", "Manage contract farming","Request for contract-manufacturing",  "Social onMarketplace", "Request for CBO account"];




  const data = [
    
    {
      title: "Basic",
      annually: "11988",
      btnText: "Sign up for Basic",
      price: "999",
      features: BasicFeature,
      newTab:"true",
      href:"http://abn.gbrfarming.com/auth/3/signup"
    },

    {
        title: "Standard",
        annually: "15588",
        btnText: "Sign up for Standard",
        price: "1299",
        features: StandardFeature,
        newTab:"true",
        href:"http://abn.gbrfarming.com/auth/3/signup"
      },

      {
        title: "Preferred",
        annually: "17988",
        btnText: "Sign up for Preferred",
        price: "1499",
        features: PrefferedFeature,
        newTab:"true",
        href:"http://abn.gbrfarming.com/auth/3/signup"
      },
      {
        title: "Premium",
        annually: "22788",
        btnText: "Sign up for Premium",
        price: "1899",
        features: PremiumFeature,
        newTab:"true",
        href:"http://abn.gbrfarming.com/auth/3/signup"
      },

      {
        title: "Customized",
        customText:"Let's Talk !",
        // annually: "13188",
        btnText: "Sign up for Customized",
        // price: "1099",
        features: CustomizedFeature,
        newTab:"true",
        href:"http://abn.gbrfarming.com/auth/3/signup"
      },


     
  ];

  return (
    <>
      <Row>
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex mb-4">
                <PricingCards href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText}/>
            </Col>
        })}
      </Row>
    </>
  );
}
