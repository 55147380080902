import React from "react";
import Icon4 from "../../images/farmer.svg";
import Icon5 from "../../images/growth.svg";
import Icon6 from "../../images/productionmanager.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import "./AgriBusinessCard.css";
// import "./ForFarmerSection3.css"

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgriBusinessCard() {
  const benefits = [
//  {
//       image: Icon4,
//       href : "/explore-farmer",
//       title: "Farmer",
//       bodycopy:
//         "Farmers are individuals who cultivate crops and raise livestock for food, fiber, and other agricultural products. They play a vital role in feeding populations and sustaining economies worldwide. Often working long hours and facing various challenges, farmers contribute significantly to global food security and rural development.",
//     },
    {
      image: Icon4,
      href : "/explore-fpo",
      title: "FPO",
      bodycopy:
        "FPOs are collective groups formed by farmers to improve their bargaining power, access resources, and market their produce together. They empower small farmers by pooling resources, sharing knowledge, and facilitating access to credit and markets, thereby enhancing livelihoods and promoting sustainable agriculture in rural areas.",
    },


    

    {
      image: Icon4,
      href : "/explore-shg",
      title: "SHG",
      bodycopy:
        "SHGs (Self-Help Groups) are community-based organizations formed by farmers to pool resources, share knowledge, and collectively engage in agricultural activities. These groups empower farmers, particularly women, by providing access to credit, training, and market linkages, thus enhancing their livelihoods and promoting socio-economic development in rural areas.",
    },

    {
      image: Icon4,
      href : "/explore-cs",
      title: "CS",
      bodycopy:
        "CS are farmer-led organizations that enable collective action to address agricultural challenges and improve economic conditions. They help farmers pool resources, access markets, and obtain inputs at lower costs, while providing essential services like credit and technical assistance, fostering rural development.",
    },


    {
      image: Icon4,
      href : "/explore-ngo",
      title: "NGO",
      bodycopy:
        "NGOs are non-profit organizations focused on supporting agricultural development and rural communities. They offer services like education, training, and technical assistance to farmers, helping them adopt sustainable practices and access markets, ultimately promoting food security and rural development.",
    },






    // {
    //   image: Icon4,
    //   href : "/ForGrowers",
    //   title: "Growers",
    //   bodycopy:
    //     "GBR's growers are the backbone of our network, they are the Agri-producers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our ecosystem.",
    // },
    // {
    //   image: Icon5,
    //   href: "/FarmerFranchise",
    //   title: "Farmer Franchise ",
    //   bodycopy:
    //     "	At the heart of our agricultural network, farmer franchises stand as the driving force. Beyond cultivating the land, they diligently oversee and manage our verified growers and assets, playing an indispensable role in the success of our ecosystem.",
    // },
    // {
    //   image: Icon6,
    //   title: "Production Manager ",
    //   bodycopy:
    //   "Production managers take on a multifaceted role in our system, overseeing contract manufacturing, driving cultivation, and efficiently managing both growers and valuable assets."
    // },
  ];
  return (
      <div className="for-farmer-section3 subSectionWrapper pb-0">
        {/* <h2 className="title text-center mb-5 mb-lg-5 container-title">Benefits</h2> */}
        <Row>
          {benefits.map((e) => {
            return (
              <Col md={6} className="d-flex">
                <div className="benefitsCardContainer benefitsCardContainer--mod mb-lg-0 mb-5">
                  <img
                    src={e.image}
                    alt="icon"
                    className="mb-4 cursor-initial"
                  />

                  <h3>{e.title}</h3>
                  <p className="mb-0"> {e.bodycopy} </p>
                  <NavLink to={e.href}  className="text-white">
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-3 "
                    >
                     Explore
                    </Button>
                  </NavLink>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
  );
}
