import React from "react";
import Icon4 from "../../images/gbrFarmerBenefits/increase.svg";
import Icon5 from "../../images/gbrFarmerBenefits/save-money.svg";
import Icon7 from "../../images/gbrFarmerBenefits/pay-per-click.svg";
import Icon8 from "../../images/gbrFarmerBenefits/smart-farm.svg";
import Icon10 from "../../images/global-market.svg";

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function CBOCustomerBenefits() {
  const benefits = [
   
    {
      image: Icon4,
      title: "Sell Produce",
      bodycopy:
        "Community members can maximize their produce sales through the support of CBOs, ensuring increased profitability and economic well-being.        ",
    },
    {
      image: Icon4,
      title: "Buy Inputs",
      bodycopy:
        "Community member has a ability to access affordable input purchases, which are often sourced locally, fostering economic growth within the community.",
    },
    {
      image: Icon10,
      title: "Empowering Choices  ",
      bodycopy:
      "CBO customers enjoy the advantage of autonomy, as they can choose and join a Community-Based Organization that best aligns with their needs and aspirations, fostering a sense of empowerment and community engagement."
    },

    {
      image: Icon10,
      title: "Access to farmers facilitation services ",
      bodycopy:
      " Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers. "
    },
    // {
    //   image: Icon6,
    //   title: "Access to Machinery ",
    //   bodycopy:
    //     "Progressive farmers & micro small organisations benefit from facilitated access to machinery, enhancing operational efficiency and productivity."
    // },
    // {
    //   image: Icon7,
    //   title: "Monetize unutilized selling capacity ",
    //   bodycopy:
    //     "Boost your agri-retail business by monetizing unutilized selling capacity, offering a lucrative opportunity to agri-entrepreneurs and maximizing profits through efficient product distribution."
    // },
    // {
    //   image: Icon8,
    //   title: "Access to farmers facilitation services ",
    //   bodycopy:
    //     "Experience the advantages of our seamless field support, including migration assistance, asset care, labor card facilitation, and buyback support. This comprehensive package ensures operational efficiency and provides peace of mind for farmers."
    // },
  ];
  return (
    <Container>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">CBO Customers Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </Container>

  );
}
