import React from "react";
import { Container } from "@material-ui/core";
import ABNNET from "../../../images/gbrHome/web_info_3.gif";
import ABNNETMOB from "../../../images/gbrHome/web_info_3.gif";
import "./LandingPageSolution.css";
export default function LandingPageSolution() {
  return (
    <>
      <Container className="solutionEcosystem-container subSectionWrapper" id="ExploreABN">
        <h2 className="container-title text-center ">
          Our Approach & Ecosystem
        </h2>
        <p className="container-para text-center mx-0 mx-md-4">

Approach is to provide Agri Business Network (ABN),  Community Based Network (CBN)  and Digital Transformation Network (DTN) to establish robust ecosystem for Agribusiness.
        </p>

        <img className="mob-visible" src={ABNNET} alt="Approach and Ecosystem"/>
        <img className="mob-hiden" src={ABNNETMOB} alt="Approach and Ecosystem" />
      </Container>

      {/* <div className="mt-5 mb-5 ">
        <HomeSubSection
          fontBold="true"
          className="rowReverseAbout align-items-center"
          listItems={<ABNBenefits/>}
   
      description="ABN is a Blockchain enabled online community for agribusiness to maximize profit. Agribusiness can join agri business network to access following benefits"
        
          descriptionTitle="Agri Business Network (ABN)  & Benefits
          "
          src={ABN}
          href1="/ABN"
          buttoncont1="Explore Ecosystem for agribusiness"
          grid="true"
          btn2= "true"

        />
      </div>

      <div className="mt-5 mb-5">
        <HomeSubSection
          fontBold="true"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod align-items-center"
          listItems={<PNBenefits/>}
          description="  Production network also called Digital Transformation Network (DTN) is designed to manage investable assets by agribusiness for following benefits
          "
          descriptionTitle="Production Network (PN) & Benefits"
          src={PN}
          href1="/DTNNetwork"
          buttoncont1="Explore Production Network"
          grid="true"
          btn2= "true"

        />
      </div>

      <div className="mt-5 ">
        <HomeSubSection
          fontBold="true"
          className="rowReverseAbout align-items-center"
          listItems={<CBNBenefits/>}
          description="Agribusiness can Build their Own Community network (Virtual Clubs) to obtain group organic certification and find the best partners for local trade

          "
          descriptionTitle=" Community Based Network (CBN) & Benefits"
          src={CBN}
          href1="/CBNNetwork"
          buttoncont1="Explore Ecosystem for Producers"
          grid="true"
          btn2= "true"


        />
      </div> */}
    </>
  );
}
