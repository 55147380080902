import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Brand_Challenges_29-02-2024-removebg-preview.png";

import Monetize from "../../images/Techonological adoption.svg";
import Order from "../../images/supply-chain-management.svg";

const BrandChallenges = () => {
    const challanges =[
    
 
     
      {
        title:"Technology Adoption",
        description:"Agrifood brands must keep pace with technological advancements in areas such as provenance, precision farming, predictive maintenance,  data analytics, and e-commerce. Failure to adopt these technologies can result in inefficiencies and missed opportunities for growth.  ",
        image:Monetize
      },
      {
        title:"Supply Chain Sustainability",
        description:"There is increasing pressure on agrifood brands to adopt sustainable practices throughout their supply chains, from sourcing raw materials to packaging and distribution. This includes reducing greenhouse gas emissions, minimizing waste, and promoting ethical labor practices.",
        image:Order
      },
    //   {
    //     title:"Market Access",
    //     description:"Farmers face the challenge of limited market access, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.",
    //     image:Ecosystem
    //   },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
    //  {
    //     title:"Easy Technology adoption ",
    //     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by agri-producers.",
    //     image:Technology
    //   }
    ]
    return(
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Brand challenges
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex">
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange Icon" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
    )
};

export default BrandChallenges
