import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
import "./AgriBusiness.css"
import SocialMarketNavbar from '../SocialMarketNavbar/SocialMarketNavbar';

export default function AgriBusiness() {
  const userTypes= ["FOOD-PROCESSOR"]

   
 
  return (
    <>
    <HeaderComponent className="noShadow"/>
    <SocialMarketNavbar/>
    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={["Get started by introducing yourself to a Food Brands to submit your ",<br className='d-md-block d-none'></br>, "services brochure (Catalog ) and also create own network of micro-entreprenures  "]}

        /> 

<div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'>Brands (Food Processor Partition)</h2>

    <ClubMainPage type={userTypes} catalog="true"/>
    </div>
           <FooterComponent/>
    </>
  )
}
