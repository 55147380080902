import React from "react";
import Icon1 from "../../images/CustomerBenefit/return-on-investment.svg";
import Icon2 from "../../images/CustomerBenefit/quality-assurance.svg";
import Icon3 from "../../images/CustomerBenefit/time-manager.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function FarmEnterpriseCustomerBenefits() {
  const benefits = [
   
    {
      image: Icon1,
      title: "Better RoI",
      bodycopy:
        "Better return on investment (RoI) serves as a significant benefit for farm enterprise customers, enhancing their profitability and sustainability.",
    },

    {
      image: Icon2,
      title: "Ensured Quality ",
      bodycopy:
        "Ensured quality in production ensures farm enterprise customers receive high-quality products, enhancing their competitiveness and marketability."
    },
    {
      image: Icon3,
      title: "Real Time Visibility ",
      bodycopy:
        "Real-time visibility in the production process enables farm enterprise customers to track and trace operations efficiently, enhancing transparency and operational control."
    },
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title">Farm Enterprise Customer Benefits</h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
