import React from "react";
import img1 from "../../images/optionalChoice.png";
import img2 from "../../images/improvement.png";
import img3 from "../../images/job-offer.png";
import img4 from "../../images/double-check.png";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AAMCContribution() {
  const benefits = [
    {
      img: img1,
      title: "New Asset Class",
      bodycopy: "Can Introduce a new tangible asset class for investment opportunities.",
    },
    {
      img: img2,
      title: "Create Agri-entrepreneurs",
      bodycopy: "Agri-business transforms agri-producers into agri-entrepreneurs, fostering a dynamic agricultural entrepreneurship ecosystem.      ",
    },
    {
      img: img3,
      title: "Create Jobs",
      bodycopy:
        "Investable trade concept for agriculture asset class will contribute to the creation of additional rural employment opportunities.",
    },
    // {
    //   img: img2,
    //   title: "Improve Economy of Growers",
    //   bodycopy: "Enhancing grower economies through efficient farming and improved market access.      ",
    // },
    // {
    //   img: img3,
    //   title: "Create Jobs",
    //   bodycopy:
    //     "Investable trade concept for agriculture asset class will contribute to the creation of additional rural employment opportunities.",
    // },
    {
      img: img2,
      title: "Improve Economy of Country",
      bodycopy: "Investable trade in Agriculture bring a cascading increase in Agricultural production and  improves the GDP.",
    },
    // {
    //   img: img4,
    //   title: "Easy Capital Access",
    //   bodycopy:
    //     "Alternate options for Agri-Entreprenuer to raise investment and Investor to invest in tangible assets",
    // },
  ];
  return (
    <div className=" subSectionWrapper ">
      <h2 className="text-center container-title">
        Contribution
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={6} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
