import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./IAAMarketplace.css";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import ClubMainPage from "../Club/ClubMainPage";
import IaaMarketNavbar from "../IaaMarketNavbar/IaaMarketNavbar";
export default function IAAMarketplace() {
  const userTypes = ["AssetOEM"];

 
  return (
    <>
      <HeaderComponent className="noShadow" />
      <IaaMarketNavbar/>
      <UpdatesBanner
        className="IAAMarketplaceBanner text-white"
        bannerText="Get started by introducing yourself to an investor & submit proposal"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />

      <div className="subSectionWrapper">
        <ClubMainPage type={userTypes} explore= "trueinvest" />
      </div>
      <FooterComponent />
    </>
  );
}
